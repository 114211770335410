<template>
  <div class="chat-face">
    <div class="title">基本材料</div>
    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <td>材料名称</td>
            <td>材料类型</td>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in list">
            <tr v-if="item.selected && item.id !== '10105' && item.id !== '10100'" :key="item.id">
              <td width="50%">{{ item.name }}</td>
              <td>原件</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-if="remarks" class="notice">
      备注：{{ remarks }}
    </div>
    <template v-if="showBank">
      <div class="title" style="padding-top: 40px;">补充材料 <van-icon v-if="selectBank || bindBank" name="edit" @click="goBankList" /></div>
      <div v-if="selectBank" class="list">
        <div class="item">
          <div class="icon"><img :src="selectBank.bankLogo" alt=""></div>
          <div class="cont">
            <div class="tt">{{ selectBank.bankName }}</div>
            <div class="ct">{{ selectBank.cardNo }}</div>
          </div>
        </div>
      </div>
      <div v-else-if="bindBank" class="list">
        <div class="item">
          <div class="icon"><img :src="bindBank.bankLogo" alt=""></div>
          <div class="cont">
            <div class="tt">{{ bindBank.bankName }}</div>
            <div class="ct">{{ bindBank.cardNo }}</div>
          </div>
        </div>
      </div>
      <div v-else class="list">
        <div class="add" @click="goBankList"><span><van-icon name="add-o" /></span>添加银行卡</div>
      </div>
      <div class="bottom">
        <van-button
          round
          block
          type="info"
          color="linear-gradient(90deg, #FE9D02 0%, #F78017 100%)"
          style="margin-bottom: 20px;"
          @click="goConfirm"
        >
          确定
        </van-button>
      </div>
    </template>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getDemandMaterialByOderAndType } from '@/api/product'
import { getCardByOrderId, bindOrderCard } from '@/api/finance'
export default {
  name: 'ChatFaceInfo',
  data() {
    return {
      list: [],
      orderId: '',
      remarks: '',
      time: null,
      bindBank: null,
      showBank: false
    }
  },
  computed: {
    ...mapGetters([
      'selectBank'
    ])
  },
  mounted() {
  },
  activated() {
    console.log(this.time)
    console.log(this.$route.query.time, 'qery')
    if (this.time !== this.$route.query.time) {
      //
      this.$store.dispatch('user/selectBank', null)
      this.orderId = this.$route.query.orderId
      this.getList()
      this.time = this.$route.query.time
    }
    console.log(this.selectBank, 'selectBank')
  },
  methods: {
    goBankList() {
      // 状态为1的时候是选择银行卡
      this.$router.push({ path: '/bankList', query: { status: 1 }})
    },
    async goConfirm() {
      const cardId = this.selectBank ? this.selectBank.id : this.bindBank.id
      await bindOrderCard({ orderId: this.orderId, cardId })
      this.$store.dispatch('user/setFaceSignStatus', true)
      this.$router.go(-2)
    },
    async getList() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getDemandMaterialByOderAndType({ orderId: this.orderId, type: 1 })
      loading.clear()
      this.list = res.datas
      this.list.map(async item => {
        if (item.id === '10100' && item.selected) {
          this.remarks = item.remarks
        }
        if (item.id === '10105' && item.selected) {
          this.showBank = true
          const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
          const res = await getCardByOrderId({ orderId: this.orderId })
          loading.clear()
          this.bindBank = res.datas && res.datas[0]
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.chat-face {
  padding: 16px;
  .title {
    padding-bottom: 10px;
    font-size: 16px;
    i {
      font-size: 20px;
      color: #FF7700;
    }
  }
  .table {
    box-shadow: 0px 3px 6px rgba(0,0,0,0.06);
    border-radius: 8px;
    table {
      width: 100%;
      text-align: center;
      thead {
        background: #F5F5F7;
        td {
          height: 50px;
          color: #999999;
        }
      }
      tbody {
        td {
          height: 70px;
          span {
            font-size: 12px;
          }
        }
      }
      td {
        border: 1px solid #F3F3F5;
      }
    }
  }
  .notice {
    padding: 24px 0 0 0;
    line-height: 21px;
    color: #999999;
  }
  .list {
    .add {
      display: flex;
      height: 124px;
      justify-content: center;
      align-items: center;
      color: #666666;
      background: #F5F5F7;
      span {
        margin-right: 10px;
        font-size: 20px;
        color: #FF7700;
      }
    }
    .item {
      position: relative;
      display: flex;
      align-items: center;
      width: 343px;
      height: 124px;
      margin-bottom: 14px;
      background: url('~@/assets/user/card_bg@2x.png');
      background-size: 100%;
      .icon {
        width: 50px;
        height: 50px;
        margin-left: 24px;
        border-radius: 8px;
        background: #FFDDC2;
      }
      .cont {
        padding-left: 16px;
        color: #fff;
        .tt {
          font-size: 16px;
        }
        .ct {
          padding-top: 10px;
          font-size: 20px;
        }
      }
    }
  }
  .bottom {
    padding: 32px 24px 0 24px;
  }
}
</style>
